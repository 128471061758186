<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="newSalesProspect"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id })" :style="{ color: getAccountNameColor(item) }">
                                    {{ item.sp_primary_details.account_name }}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <!---------------FOR RE-ACCREDITATION---------------->
                            <template v-slot:[`item.sp_primary_details.next_reaccreditation_date`]="{item}">
                                {{formatReAccreditationDate(item.sp_primary_details.next_reaccreditation_date)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.status === 'Pending (Presentation)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.status === 'For negotiation'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.status === 'pending'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.profile === 'incomplete' && item.status === 'Accepts Partnership'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Evaluation (Returned)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Archived'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'sales-prospecting/initial-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.profile === 'incomplete' && item.status === 'existing'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/existing-update-initial/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit initial draft encoder -->
                                    </v-btn>

                                    <!---------------FOR RE-ACCREDITATION---------------->
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.reaccreditation_status === 'Due For Re-Accreditation' && item.status === 'For Re-Accreditation'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/re-accreditation/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.reaccreditation_status === 'Due For Re-Accreditation' && item.status === 'Reaccreditation Onboarding (Returned)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/re-accreditation/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    
                                    <v-btn
                                        v-if="currUser.user_role === 'approver' && (item.sp_primary_details.reaccreditation_status === 'Due For Re-Accreditation' && item.status === 'Onboarding Approval Pending')"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/re-accreditation-onboarding/'+item.id})"
                                        >
                                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.reaccreditation_status === 'Due For Re-Accreditation' && item.status === 'Onboarding Approval Pending'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/re-accreditation-onboarding/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.account_status === 'Active' && checkDate(item.created_at)) && item.sp_primary_details.reaccreditation_status === null && 
                                        !(item.status === 'Initial Evaluation Complete' ||
                                        item.status === 'Initial Evaluation Committee Approved' ||
                                        item.status === 'Initial Evaluation Committee Approval Pending' ||
                                        item.status === 'Evaluation (Returned)' ||
                                        item.status === 'Archived'|| item.status === 'Scorecard Failed')"
                                        text
                                        color="green"
                                        @click="$router.push({ path:'/existing-sales-prospecting/re-accreditation/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <!---------------------------------------------------------->
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.status === 'Active'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Initial Evaluation Complete'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>


                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Initial Existing Complete'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>


                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && !item.sp_primary_details.is_draft && item.status === 'Onboarding Approval Pending' && item.sp_primary_details.reaccreditation_status === null"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final not approved encoder -->
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.sp_primary_details.is_draft && item.status === 'Onboarding Approval Pending' && item.sp_primary_details.reaccreditation_status === null"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final not approved encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && !item.sp_primary_details.is_draft && item.status === 'Existing (Validation Approval Pending)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final not approved encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && ((item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Initial Evaluation Committee Approval Pending' || item.status === 'Initial Evaluation Committee Approved')"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'sales-prospecting/initial-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted initial not approved encoder -->
                                    </v-btn>
                                    
                                    <v-btn 
                                        v-if="(item.status === 'Initial Evaluation Committee Approval Pending' || item.status === 'Initial Evaluation Committee Approved') && currUser.user_role === 'approver' && (currUser.is_evaluation_committee === '1' || currUser.is_president === '1' )"
                                        text
                                        color="primary"
                                        @click="evalApproverView(item.id)"
                                        >
                                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                                        <!-- view submitted initial approver -->
                                    </v-btn>

                                    <v-btn 
                                        v-if="(item.status === 'Onboarding Approval Pending' && item.sp_primary_details.reaccreditation_status === null) && currUser.user_role === 'approver' && currUser.is_onboarding_committee === '1'" 
                                        text
                                        color="primary"
                                        @click="onboardingApproverView(item.id)"
                                        >
                                        <!-- view submitted final approver -->
                                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                                    </v-btn>
                                    <v-btn 
                                        v-if="(item.status === 'Existing (Validation Approval Pending)') && currUser.user_role === 'approver' && currUser.is_validation_committee === '1'"
                                        text
                                        color="primary"
                                        @click="validationApproverView(item.id)"
                                        >
                                        <!-- view submitted final approver -->
                                        <v-icon>mdi-checkbox-marked-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Validation (Returned)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Onboarding (Returned)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Existing (Temporarily Terminated)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.profile === 'complete' && item.status === 'Onboarding (Hanging)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/update/'+item.id})"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Terminated'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final not approved encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Evaluation (Hanging)'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final not approved encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Existing Complete'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final -->
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.sp_primary_details.is_draft || !item.sp_primary_details.is_draft) && item.status === 'Onboarding Approved'"
                                        text
                                        color="primary"
                                        @click="$router.push({ path:'/sales-prospecting/final-view-only/'+item.id})"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                        <!-- view submitted final -->
                                    </v-btn>

                            

                                    <v-btn 
                                        v-if="(item.is_generate === 1) && currUser.user_role === 'encoder' && item.status !== 'Terminated'"
                                        text
                                        color="primary"
                                        small
                                        >
                                        <MOA :item="item.id"> </MOA>
                                    </v-btn>

                                    <v-btn 
                                        v-if="currUser.user_role === 'encoder' && item.status !== 'Terminated' && item.status !== 'Deletion Pending'"
                                        text
                                        color="red"
                                        small
                                        >
                                        <Termination :item="item.id"> </Termination>
                                    </v-btn>
                                    
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-fab-transition v-if="currUser.user_role != 'approver'">
                <v-speed-dial
                    v-model="fab"
                    right
                    bottom 
                    fixed
                    transition="slide-y-reverse-transition"
                    >
                    <template v-slot:activator>
                        <v-btn
                        v-model="fab"
                        :color="fab ? '#979797' : 'success'"
                        fab
                        >
                        <v-icon v-if="fab" color="white">
                            mdi-close
                        </v-icon>
                        <v-icon v-else>
                            mdi-plus
                        </v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                dark
                                small
                                color="#F76F34"
                                v-on="on"
                                v-bind="attrs"
                                @click="$router.push({path: 'sales-prospecting/existing'})"
                                >
                                <v-icon small>mdi-account-settings</v-icon>
                            </v-btn>
                        </template>
                        <span>Existing</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                dark
                                small
                                color="#6633CC"
                                v-on="on"
                                v-bind="attrs"
                                @click="$router.push({path: '/sales-prospecting/new/false'})"
                                >
                                <v-icon small>mdi-account-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>New</span>
                    </v-tooltip>
                </v-speed-dial>
            </v-fab-transition>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'
import MOA from '../components/modules/salesProspecting/MOA.vue';
import Termination from '../components/modules/salesProspecting/Termination.vue';

export default {
  name: 'Users',
  components:{
    MOA: MOA,
    Termination: Termination
  },
  data () {
    return {
        search: '',
        fab: false,
        salesProspecting: [],
        newSalesProspect: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'BP ID Number',
                sortable: true,
                value: 'bp_idno'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Profile',
                sortable: true,
                value: 'profile'
            },
            {
                text: 'Approval Status',
                sortable: true,
                value: 'status'
            },
            {
                text: 'Re-Accreditation Status',
                sortable: true,
                value: 'sp_primary_details.reaccreditation_status'
            },
            {
                text: 'Re-Accreditation Date',
                sortable: true,
                value: 'sp_primary_details.next_reaccreditation_date',
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        greenStatus: ['Initial Evaluation Complete','Onboarding Approved','Initial Existing Complete','Existing Complete','Merged (IS)','Existing Complete (IS)'],
        orangeStatus: ['pending','Inital Evaluation Committee Approval Pending','Initial Evaluation Committee Approved','Onboarding Approval Pending','existing','Pending (Presentation)','For negotiation','Accepts Partnership','Evaluation (Returned)','Termination Pending','Deletion Pending','Initial Evaluation Committee Approval Pending','Onboarding (Returned)','Validation (Returned)','Existing (Validation Approval Pending)'],
        redStatus: ['Scorecard Failed','Not accepted','Archived','Existing (Temporarily Terminated)','Terminated','Evaluation (Hanging)'],
    }
  },
  computed: {
    getAccountNameColor() {
        return (item) => {
            if (this.greenStatus.includes(item.status)) {
                return 'green'
            } else if (this.orangeStatus.includes(item.status)) {
                return 'orange'
            } else if (this.redStatus.includes(item.status)) {
                return 'red'
            } 
            else {
                return 'black'
            }
        }
    },
    ...mapGetters({
      currUser: 'auth/currUser'
    })
  },
  async mounted () {
    const res = await this.$store.dispatch('salesProspecting/doGetAllSalesProspecting')
    this.salesProspecting = res.data.result

   

    if (this.currUser.is_evaluation_committee === '1'){
        this.salesProspecting.map(item => {
            if (item.is_existing == 0 && item.created_by == this.currUser.name){
                this.newSalesProspect.push(item)
            }
        })
    } else if (this.currUser.is_evaluation_committee === '0'){
        this.salesProspecting.map(item => {
            if (item.is_existing == 0){
                this.newSalesProspect.push(item)
            }
        })
    }

    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
    
  },
  methods: {
    evalApproverView (item) {
        this.$router.push({
            path: '/sales-prospecting/eval-approver/' + item
        })
    },
    onboardingApproverView (item) {
          this.$router.push({
            path: '/sales-prospecting/onboarding-approver/' + item
        })
    },
    validationApproverView (item) {
          this.$router.push({
            path: '/sales-prospecting/validation-approver/' + item
        })
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    formatReAccreditationDate(date) {
    if (!date) return ''; // handle empty date
    return date.split(' ')[0]; // Extracts only the date part
  },
    checkDate(date){
        console.log(this.formatDate(date));
        return this.formatDate(date) < '2024-12-04';
    },
    newSalesProspecting () {
        this.$router.push({
            path: '/sales-prospecting/new'
        })
    }
  }
}
</script>

<style scoped>
.initial-status {
  color: blue;
}

.final-status {
  color: green;
}
</style>